import React, { useEffect, useState } from "react"
import { Badge } from "reactstrap"
import { useSelector } from "react-redux"

import Layout from "../components/layout"
import Leaderboard from "../components/leaderboards/index"
import SEO from "../components/seo"
import { LeaderboardGroup, LeaderboardTime, LeaderboardType } from "../hasura/queries/user"
import { userSelector, UserState } from "../hasura/slices/user"

// @ts-ignore
import CONFIG from "../config"

export default function Leaderboards() {
  const { user, isTeacher }: UserState = useSelector(userSelector)

  const [leaderboardType, setLeaderboardType] = useState(LeaderboardType.Stars)
  const [leaderboardGroup, setLeaderboardGroup] = useState<LeaderboardGroup | undefined>()
  const [leaderboardTime, setLeaderboardTime] = useState(LeaderboardTime.Weekly)

  const isGlobal = leaderboardGroup === LeaderboardGroup.Global
  const isStars = leaderboardType === LeaderboardType.Stars
  const isWeekly = leaderboardTime === LeaderboardTime.Weekly

  const schoolName = isTeacher ? user?.group?.display_name || user?.school || "School" : "School"

  useEffect(() => {
    if (!user || leaderboardGroup) return

    setLeaderboardGroup(isTeacher ? LeaderboardGroup.Class : LeaderboardGroup.Global)
  }, [user, leaderboardGroup])

  return (
    <Layout noStyles>
      <SEO title="Leaderboards" />

      {leaderboardGroup && (
        <div className="mt-4 text-center">
          <h1 className="pb-3 text-xl text-center bold mb-0 text-xl">
            {isGlobal ? "World" : schoolName} | {isWeekly ? "Weekly" : "All Time"} | {isStars ? "Stars" : "Accuracy"}
          </h1>

          <Leaderboard
            accuracyLocked={!isTeacher && (user?.level || 0) < 10}
            hasClassroom={user?.classroom !== null || isTeacher}
            leaderboardGroup={leaderboardGroup}
            leaderboardTime={leaderboardTime}
            leaderboardType={leaderboardType}
            setLeaderboardGroup={setLeaderboardGroup}
            setLeaderboardTime={setLeaderboardTime}
            setLeaderboardType={setLeaderboardType}
          />

          <Badge className="text-xs px-3 py-1 mt-2 bg--gray6 text--white" pill>
            Weekly leaderboards reset at the beginning of every week.
          </Badge>
        </div>
      )}
    </Layout>
  )
}
